<template>
  <div class="image-editor" :style="{width:canvasSize+'px'}">
    <canvas ref="canvasElement" :width="canvasSize" :height="canvasSize"></canvas>
  
    <div class="toolbar">
      <input type="file" @change="loadUserImage" />
      <button @click="downloadImage">Download</button>
    </div>
  
    <div v-if="userImage" class="zoom-control">
      <label for="zoom-range">Zoom: </label><br/>
      <input
          id="zoom-range"
          type="range"
          :min="0.01"
          :max="getMaxZoomLevel()"
          step="0.01"
          v-model="zoomLevel"
          @input="zoomUserImage"
      /><br/>
      <span>{{ (zoomLevel * 100).toFixed(0) }}%</span><br/>
      <b class="warn">
          Image added, use the zoom to increase or reduce the size of the image<br/>
          NOTE: YOUR PICTURE IS HIDING BEHIND THE BACKGROUND, IF YOU CAN'T SEE IT, CLICK OR TOUCH THE CENTER OF THE BACKGROUND
      </b>
    </div>
  </div>
  
  
</template>


<script>
import { ref, onMounted, onBeforeUnmount, reactive, watch } from 'vue';
import { fabric } from 'fabric';
import backgroundImageURL from '@/assets/default.png';

export default {
  props:{bgImg:{type: String, default:""}},
  setup(props) {
    const canvasElement = ref(null);
    const canvas = ref(null);
    const userImage = ref(null);
    const canvasSize = ref(Math.min(window.innerWidth * 0.9, 800));
    const zoomLevel = ref(1);
    const backgroundImage = ref(null);
    const imagePos=reactive({top:0,left:0});
    const imageSize=reactive({w:0,h:0});
    const bg=ref(props.bgImg == "" ? backgroundImageURL : props.bgImg);
    console.log(bg)

    const updateCanvasSize = () => {
      canvasSize.value = Math.min(window.innerWidth * 0.9, 800);
      if (canvas.value) {
        canvas.value.setWidth(canvasSize.value);
        canvas.value.setHeight(canvasSize.value);
        loadBackgroundImage();
      }
    };

    const loadBackgroundImage = () => {
      fabric.Image.fromURL(bg.value, (img) => {
          backgroundImage.value = img; // Store reference to background image
          if (canvas.value) {
              img.set({
              left: 0,
              top: 0,
              selectable: false,
              evented: false,
              scaleX: canvas.value.width / img.width,
              scaleY: canvas.value.height / img.height,
              });
              canvas.value.clear();
              canvas.value.add(img);
              img.moveTo(1); // Ensure background is on top of the user image
              canvas.value.renderAll();
          }
      }, { crossOrigin: 'Anonymous' });
    };

    const loadUserImage = (event) => {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        fabric.Image.fromURL(e.target.result, (img) => {
          if (canvas.value) {
            userImage.value = img; // Set the reactive userImage reference
            imagePos.left=canvas.value.width / 2 - img.width / 4;
            imagePos.top=canvas.value.height / 2 - img.height / 4;
            img.set({
              left: imagePos.left,
              top: imagePos.top,
              scaleX: 0.5,
              scaleY: 0.5,
            selectable: true,
            evented: true,
            });
            zoomLevel.value=0.5
            canvas.value.add(img);
            imageSize.w=img.getScaledWidth()
            imageSize.h=img.getScaledHeight();
            img.moveTo(0); // Place user image behind the background image
            canvas.value.renderAll();
            img.on('mousedown', function () {
              // Prevent the image from being brought to the front
              img.moveTo(0); // Move to the back (behind other objects)
              console.log('User image clicked!');
              // You can perform any other action here on click
            });
            img.on('mouseup', function () {
      console.log('Mouse up on user image, deselecting...');
      canvas.value.discardActiveObject(); // Deselect the current active object
      canvas.value.renderAll(); // Re-render the canvas to update the view
    });

          }
        });
      };
      reader.readAsDataURL(file);
    };

  const zoomUserImage = () => {
      if (userImage.value && canvas.value) {
          const isMobile = window.innerWidth <= 768; // Detect mobile devices
          const maxMobileWidth = 1500;

          // Calculate the current width of the image
          const currentWidth = userImage.value.width * zoomLevel.value;

          // Adjust the zoom level if on mobile and width exceeds the max width
          if (isMobile && currentWidth > maxMobileWidth) {
          zoomLevel.value = maxMobileWidth / userImage.value.width;
          }

          // Apply the zoom level to the image
          userImage.value.scaleX = zoomLevel.value;
          userImage.value.scaleY = zoomLevel.value;
          canvas.value.renderAll();
      }
  };

    const downloadImage = () => {
      // Create an off-screen canvas
      const tempCanvas = new fabric.Canvas();
      const targetWidth = 2000;
      const targetHeight = 2000;

      // Resize background and user image to fit within the target canvas size
      const scaleX = targetWidth / backgroundImage.value.width;
      const scaleY = targetHeight / backgroundImage.value.height;

      // Scale background image
      const backgroundCopy = fabric.util.object.clone(backgroundImage.value);
      backgroundCopy.set({
        scaleX,
        scaleY,
        left: 0,
        top: 0,
        selectable: false,
        evented: false,
      });

      // Add the background image last (ensure it's on top in the off-screen canvas)
      tempCanvas.setWidth(targetWidth);
      tempCanvas.setHeight(targetHeight);

      if (userImage.value) {
        const userScaleX =  (targetWidth / canvas.value.width);
        const userScaleY =  (targetHeight / canvas.value.height);
        console.log("scale ",userScaleX," ",userScaleY," zoom level", zoomLevel.value)
        

        const userImageCopy = fabric.util.object.clone(userImage.value);
        userImageCopy.set({
          scaleX: userScaleX*zoomLevel.value,
          scaleY: userScaleY*zoomLevel.value,
          left: imagePos.left * userScaleX, // Adjust relative position
          top: imagePos.top * userScaleY,
        });
        console.log(`Original- Left: ${imagePos.left}`);
        console.log(`User Image Copy - Left: ${userImageCopy.left}`);
        // Add the user image first to ensure it's behind the background image
        tempCanvas.add(userImageCopy); // User image is added first
      }

      tempCanvas.add(backgroundCopy);

      // Scale the user image similarly
      

      // Export the image from the temporary canvas and create a download link
      const dataUrl = tempCanvas.toDataURL({ format: 'png' });

      // Create a temporary download link and trigger the download
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'edited-image.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up by removing the temporary canvas
      tempCanvas.dispose();
    };

  const getMaxZoomLevel = () => {
      const isMobile = window.innerWidth <= 768; // Detect mobile devices
      const maxMobileWidth = 1500;

      if (isMobile && userImage.value) {
          return maxMobileWidth / userImage.value.width;
      }
      return 2; // Default max zoom level for non-mobile devices
  };

    onMounted(() => {
      canvas.value = new fabric.Canvas(canvasElement.value);
      loadBackgroundImage();

      canvas.value.on('object:modified', (event) => {
        const l=imagePos.left,
        t=imagePos.top;
        if (event.target._element === userImage.value._element) {
          imagePos.left=userImage.value.left;
          imagePos.top=userImage.value.top
        }
        console.log("difference is ",imagePos.left - l, " ",imagePos.top - t)
      });

      canvas.value.on('object:moving', (event) => {
          const obj = event.target;
          console.log("movinggggggg")

          // Check if the object corresponds to the user image
          if (obj._element === userImage.value?._element) {
            console.log("moving")
              const canvasWidth = canvas.value.width;
              const canvasHeight = canvas.value.height;
              const scaledWidth = obj.getScaledWidth();
              const scaledHeight = obj.getScaledHeight();

              // Enforce boundaries to ensure at least part of the image is always visible
              if (obj.left > canvasWidth - 10) obj.left = canvasWidth - 10;
              if (obj.top > canvasHeight - 10) obj.top = canvasHeight - 10;
              if (obj.left + scaledWidth < 10) obj.left = 10 - scaledWidth;
              if (obj.top + scaledHeight < 10) obj.top = 10 - scaledHeight;
          }
      });

      window.addEventListener('resize', updateCanvasSize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateCanvasSize);
    });

    watch(
      () => props.bgImg,
      (newData) => {
      console.log("adfsf ",props)
        bg.value = newData;
        loadBackgroundImage();
      },
      { deep: true, immediate: true }
    );

    return {
      canvasElement,getMaxZoomLevel,
      canvasSize,
      zoomLevel,
      userImage,
      backgroundImage,
      loadUserImage,
      zoomUserImage,
      downloadImage,
    };
  },
};

</script>




<style scoped>
.image-editor {
  text-align: center; margin: 0 auto;
}

.toolbar button {
  margin: 5px;
  padding: 8px 12px;
}

.zoom-control {
  margin-top: 10px;
}
.toolbar{    width: min(500px, 90%);
  margin: 20px auto 10px;
  border: 2px solid #000000;
  border-radius: 20px;
  padding: 0.5rem 1rem;
}
.warn{
  width: min(500px, 90%);
  display: block;
  margin: 5px auto;
  font-family: sans-serif;
  font-size: 13px;
  line-height: 1.5em;
  padding-bottom: 20px;
}
.canvas-container{margin: 10px auto;}
#zoom-range{width:90%; margin: 5px auto}
</style>
