<template>
  <ImageEditor :bgImg="bg" />
</template>

<script>
import { ref, onMounted } from 'vue';
import ImageEditor from '@/components/ImageEditor.vue';
import call from "@/composables/call.js";
import { urls } from "@/assets/js/urls";
import error from "@/assets/errorframe.png";

export default {
  name: 'App',
  components: {
    ImageEditor,
  },
  setup() {
    const bg=ref("")

    onMounted(() => {
      // Fetch the ID from a global initialization method
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("frameid")) {
        const frameid=urlParams.get("frameid")
        call({
          url:urls.frame,
          data:{getFrame:frameid},
          success:(res)=>{
            console.log(res)
            if(res.status){
              bg.value=res.msg;
            }else{
              alert(res.msg)
            }
          }
        })
      } else {
        bg.value=error
        console.warn('No userId provided during initialization!');
      }
    });

    return {
      bg,
    };
  },
};
</script>